import { template as template_0c5fec3f5bb84d7b9697c925c8918a3a } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_0c5fec3f5bb84d7b9697c925c8918a3a(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
