import { template as template_49642c5b5f2548b482df46032dba5ff9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_49642c5b5f2548b482df46032dba5ff9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
