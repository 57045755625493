import { template as template_5bdc7218b3d842ac9ad71743ceb1b842 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_5bdc7218b3d842ac9ad71743ceb1b842(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
