import { template as template_de0ef8dbdf2c4cefbcb672158b40fb36 } from "@ember/template-compiler";
const FKText = template_de0ef8dbdf2c4cefbcb672158b40fb36(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
