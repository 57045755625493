import { template as template_02387bc3b3c749b3983642f7e38d938f } from "@ember/template-compiler";
const WelcomeHeader = template_02387bc3b3c749b3983642f7e38d938f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
